
import { defineComponent, ref } from 'vue'
import { trainingCertificationConfig } from '../config/training.config'

export default defineComponent({
  setup() {
    const isEs = window.location.host === 'es.iioe.org' ? ref(true) : ref(false)

    return {
      isEs,
      trainingCertificationConfig
    }
  }
})
