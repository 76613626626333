
import { defineComponent } from 'vue'
import TrainingTop from './cpns/training-top.vue'
import TrainingIntrol from './cpns/training-introl.vue'
import TrainingCertificationSystem from './cpns/training-certification-system.vue'
import TrainingColleges from './cpns/training-colleges.vue'

export default defineComponent({
  components: {
    TrainingTop,
    TrainingIntrol,
    TrainingCertificationSystem,
    TrainingColleges
  },
  setup() {
    return {}
  }
})
