
import { defineComponent } from 'vue'
import { trainingCollegesAndUniversitiesConfig } from '../config/training.config'

export default defineComponent({
  setup() {
    return {
      trainingCollegesAndUniversitiesConfig
    }
  }
})
