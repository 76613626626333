
import { defineComponent } from 'vue'
import { trainingConfig } from '../config/training.config'

export default defineComponent({
  setup() {
    return {
      trainingConfig
    }
  }
})
