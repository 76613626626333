import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-c1b02958")
const _hoisted_1 = { class: "training-content" }
const _hoisted_2 = { class: "badge-container" }
const _hoisted_3 = { class: "container-760" }
const _hoisted_4 = { class: "badge-content" }
const _hoisted_5 = { class: "badge-title" }
const _hoisted_6 = { class: "badge-list" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "badge-content" }
const _hoisted_9 = { class: "badge-title" }
const _hoisted_10 = ["src"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.$filters.hh_t('forge')), 1),
          _createElementVNode("ul", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trainingCollegesAndUniversitiesConfig.logoItems, (item) => {
              return (_openBlock(), _createElementBlock("li", { key: item }, [
                _createElementVNode("img", {
                  src: item.image
                }, null, 8, _hoisted_7)
              ]))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("h4", _hoisted_9, _toDisplayString(_ctx.$filters.hh_t('companionUnit')), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trainingCollegesAndUniversitiesConfig.CUItems, (item) => {
            return (_openBlock(), _createElementBlock("ul", {
              class: "badge-list",
              key: item
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, (itemL) => {
                return (_openBlock(), _createElementBlock("li", { key: itemL }, [
                  _createElementVNode("img", {
                    src: itemL.image
                  }, null, 8, _hoisted_10)
                ]))
              }), 128))
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}