import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-698dca82")
const _hoisted_1 = { class: "training-page" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_training_top = _resolveComponent("training-top")!
  const _component_training_introl = _resolveComponent("training-introl")!
  const _component_training_certification_system = _resolveComponent("training-certification-system")!
  const _component_training_colleges = _resolveComponent("training-colleges")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_training_top),
    _createVNode(_component_training_introl),
    _createVNode(_component_training_certification_system),
    _createVNode(_component_training_colleges)
  ]))
}