import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-29ef4b22")
const _hoisted_1 = { class: "training-content" }
const _hoisted_2 = { class: "design-desc-container" }
const _hoisted_3 = { class: "container-1140" }
const _hoisted_4 = { class: "design-title" }
const _hoisted_5 = { class: "design-title" }
const _hoisted_6 = { class: "design-list" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "title" }
const _hoisted_9 = { class: "desc" }
const _hoisted_10 = { class: "certificate-intro-container" }
const _hoisted_11 = { class: "container-1140" }
const _hoisted_12 = { class: "certificate-intro-left" }
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "certificate-intro-right" }
const _hoisted_15 = { class: "certificate-intro-title" }
const _hoisted_16 = { class: "certificate-intro-desc" }
const _hoisted_17 = { class: "certificate-intro-label" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$filters.hh_t('freeClasses')), 1),
        _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$filters.hh_t('custom')), 1),
        _createElementVNode("ul", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trainingConfig.trainingIntrols, (item) => {
            return (_openBlock(), _createElementBlock("li", { key: item }, [
              _createElementVNode("img", {
                src: item.image
              }, null, 8, _hoisted_7),
              _createElementVNode("h5", _hoisted_8, _toDisplayString(_ctx.$filters.hh_t(item.title)), 1),
              _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$filters.hh_t(item.desc)), 1)
            ]))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("img", {
            src: _ctx.$filters.hh_t('circleImage')
          }, null, 8, _hoisted_13)
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("h4", _hoisted_15, _toDisplayString(_ctx.$filters.hh_t('buildForManagers')), 1),
          _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.$filters.hh_t('combination')), 1),
          _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$filters.hh_t('testReport')), 1)
        ])
      ])
    ])
  ]))
}