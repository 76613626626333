
import { defineComponent, PropType } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'Breadcrumb',
  props: {
    title: {
      type: String as PropType<string>
    }
  },
  setup() {
    const route = useRoute()

    const list = route.matched.filter((route) => !!route.meta.title)

    return { list }
  }
})
