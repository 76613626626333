import { ITraining } from '@/store/training/types'

export const trainingConfig: ITraining = {
  trainingIntrols: [
    {
      image:
        'https://iioe-files.iioe.org/deploy/prod/web/img/design1.7a1e5db1.svg',
      title: 'update',
      desc: 'computing'
    },
    {
      image:
        'https://iioe-files.iioe.org/deploy/prod/web/img/design2.ce1740d4.svg',
      title: 'digital',
      desc: 'blending'
    },
    {
      image:
        'https://iioe-files.iioe.org/deploy/prod/web/img/design3.6c61d44e.svg',
      title: 'earn',
      desc: 'taught'
    },
    {
      image:
        'https://iioe-files.iioe.org/deploy/prod/web/img/design4.53d9c5c5.svg',
      title: 'joinGlobal',
      desc: 'higher'
    }
  ]
}

export const trainingCertificationConfig = {
  bigData: [
    [
      [
        {
          image: require('@/assets/img/main/training/big-data3.6cbd8d02.svg'),
          label: 'general',
          hasIcon: true
        }
      ],
      [
        {
          image: require('@/assets/img/main/training/big-data2.8335c812.svg'),
          label: 'researchOriented',
          hasIcon: false
        },
        {
          image: require('@/assets/img/main/training/big-data2.8335c812.svg'),
          label: 'applicationOriented',
          hasIcon: false
        }
      ],
      [
        {
          image: require('@/assets/img/main/training/big-data1.7daf807f.svg'),
          label: 'researchOriented',
          hasIcon: false
        },
        {
          image: require('@/assets/img/main/training/big-data1.7daf807f.svg'),
          label: 'applicationOriented',
          hasIcon: false
        }
      ]
    ],
    [
      [
        {
          image: require('@/assets/img/main/training/iot.5ba7fbef.svg'),
          label: '',
          hasIcon: false
        }
      ],
      [
        {
          image: require('@/assets/img/main/training/cloud-computing.3ed03f01.svg'),
          label: '',
          hasIcon: false
        },
        {
          image: require('@/assets/img/main/training/ai.95f4ba79.svg'),
          label: '',
          hasIcon: false
        }
      ],
      [
        {
          image: require('@/assets/img/main/training/block-chain.95e80a07.svg'),
          label: '',
          hasIcon: false
        }
      ]
    ]
  ],
  levelBadgeO: [
    {
      image: require('@/assets/img/main/training/foundationalCFT.4a60e98f.png'),
      label: '',
      hasIcon: false
    },
    {
      image: require('@/assets/img/main/training/intermediateCFT.a9889f1c.png'),
      label: '',
      hasIcon: false
    },
    {
      image: require('@/assets/img/main/training/advancedCFT.f5be1e69.png'),
      label: '',
      hasIcon: false
    }
  ],
  levelBadgeT: [
    {
      image: require('@/assets/img/main/training/foundationalTCFT.b12bfa65.png'),
      label: '',
      hasIcon: false
    },
    {
      image: require('@/assets/img/main/training/intermediateTCFT.7c7c2b6f.png'),
      label: '',
      hasIcon: false
    },
    {
      image: require('@/assets/img/main/training/advancedTCFT.cf91ef78.png'),
      label: '',
      hasIcon: false
    }
  ]
}

export const trainingCollegesAndUniversitiesConfig = {
  logoItems: [
    {
      image:
        'https://iioe-files.iioe.org/deploy/prod/web/img/UNESCO.01524269.png'
    },
    {
      image: 'https://iioe-files.iioe.org/deploy/prod/web/img/iioe.9d6683ce.png'
    }
  ],
  CUItems: [
    [
      {
        image:
          'https://iioe-files.iioe.org/deploy/prod/web/img/badge10.092a2605.svg'
      },
      {
        image:
          'https://iioe-files.iioe.org/deploy/prod/web/img/badge1.311d7289.svg'
      },
      {
        image:
          'https://iioe-files.iioe.org/deploy/prod/web/img/badge2.dfec9663.svg'
      },
      {
        image:
          'https://iioe-files.iioe.org/deploy/prod/web/img/badge3.14fd3d0d.svg'
      },
      {
        image:
          'https://iioe-files.iioe.org/deploy/prod/web/img/badge4.a1db239e.svg'
      }
    ],
    [
      {
        image:
          'https://iioe-files.iioe.org/deploy/prod/web/img/badge5.d00e94fc.svg'
      },
      {
        image:
          'https://iioe-files.iioe.org/deploy/prod/web/img/badge6.72c45f77.png'
      },
      {
        image:
          'https://iioe-files.iioe.org/deploy/prod/web/img/badge7.7fc1e6f1.svg'
      },
      {
        image:
          'https://iioe-files.iioe.org/deploy/prod/web/img/badge8.38d26a89.svg'
      }
    ],
    [
      {
        image:
          'https://iioe-files.iioe.org/deploy/prod/web/img/badge9.4f04ba8f.svg'
      },
      {
        image:
          'https://iioe-files.iioe.org/deploy/prod/web/img/badge11.6468b067.svg'
      },
      {
        image:
          'https://iioe-files.iioe.org/deploy/prod/web/img/badge12.aa35bd21.png'
      },
      {
        image:
          'https://iioe-files.iioe.org/deploy/prod/web/img/badge13.ef48eb99.png'
      }
    ]
  ]
}
