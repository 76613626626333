import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_slide = _resolveComponent("slide")!
  const _component_navigation = _resolveComponent("navigation")!
  const _component_carousel = _resolveComponent("carousel")!

  return (_openBlock(), _createBlock(_component_carousel, {
    "items-to-show": 4,
    "items-to-scroll": 4
  }, {
    slides: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(_component_slide, { key: item }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", { item: item }, undefined, true)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    addons: _withCtx(() => [
      _createVNode(_component_navigation)
    ]),
    _: 3
  }))
}