
import { defineComponent, computed, ref } from 'vue'
import { useStore } from '@/store'
import Breadcrumb from '@/components/breadcrumb'
import ItemCarousel from '@/components/item-carousel'
import TrainingCard from './training-card.vue'
import hhRequest from '@/service'

export default defineComponent({
  components: {
    Breadcrumb,
    ItemCarousel,
    TrainingCard
  },
  async setup() {
    // const store = useStore()
    // // 发送网络请求
    // store.dispatch('training/getRecommendListDataAction')
    // store.dispatch('training/getInformationListDataAction')
    //
    // // 获取数据
    // const recommendList = computed(
    //   () => store.getters[`training/getRecommendList`]
    // )
    // const list = computed(() => store.getters[`training/getInformationList`])
    const list = ref(await hhRequest.get({ url: '/training' }))
    const recommendList = ref(
      await hhRequest.get({ url: '/training/recommend' })
    )

    return {
      recommendList,
      list
    }
  }
})
