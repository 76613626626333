
import { defineComponent, PropType } from 'vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  name: 'itemCarousel',
  components: {
    Carousel,
    Navigation,
    Slide
  },
  props: {
    items: {
      type: Array as PropType<any[]>
    }
  },
  setup() {
    return {}
  }
})
