import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-04457176")
const _hoisted_1 = { class: "training-content" }
const _hoisted_2 = { class: "training-certification" }
const _hoisted_3 = { class: "container-1140" }
const _hoisted_4 = { class: "training-content-title" }
const _hoisted_5 = { class: "training-content-sub-title" }
const _hoisted_6 = { class: "certification-container" }
const _hoisted_7 = { class: "level-name-wrapper" }
const _hoisted_8 = { class: "level-name-list" }
const _hoisted_9 = { class: "foundational-level" }
const _hoisted_10 = { class: "intermediate-level" }
const _hoisted_11 = { class: "advanced-level" }
const _hoisted_12 = { class: "micro-wrapper" }
const _hoisted_13 = { class: "certification-title" }
const _hoisted_14 = { class: "micro-badge-wrapper" }
const _hoisted_15 = {
  key: 0,
  class: "view-icon"
}
const _hoisted_16 = ["src"]
const _hoisted_17 = { class: "level-badge-wrapper" }
const _hoisted_18 = { class: "certification-title" }
const _hoisted_19 = { class: "level-badge-list" }
const _hoisted_20 = ["src"]
const _hoisted_21 = { class: "level-badge-wrapper" }
const _hoisted_22 = { class: "certification-title" }
const _hoisted_23 = { class: "level-badge-list" }
const _hoisted_24 = ["src"]
const _hoisted_25 = { class: "certificate-container" }
const _hoisted_26 = { class: "container-1140" }
const _hoisted_27 = { class: "certificate-title" }
const _hoisted_28 = { class: "certifcate-content" }
const _hoisted_29 = { class: "certificate-content-left" }
const _hoisted_30 = { class: "certificate-left-label" }
const _hoisted_31 = { class: "certificate-left-title" }
const _hoisted_32 = { class: "certificate-left-desc" }
const _hoisted_33 = { class: "certificate-content-right" }
const _hoisted_34 = ["src"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$filters.hh_t('certificationSystem')), 1),
        _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.$filters.hh_t('abilityToCertification')), 1),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("ul", _hoisted_8, [
              _createElementVNode("li", null, [
                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$filters.hh_t('primary')), 1)
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$filters.hh_t('theIntermediate')), 1)
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$filters.hh_t('primary')), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("h6", _hoisted_13, _toDisplayString(_ctx.$filters.hh_t('special')), 1),
            _createElementVNode("div", _hoisted_14, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trainingCertificationConfig.bigData, (item, index) => {
                return (_openBlock(), _createElementBlock("ul", {
                  key: item,
                  class: _normalizeClass(index === 0 ? 'big-data-list' : 'other-list')
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, (itemL) => {
                    return (_openBlock(), _createElementBlock("li", { key: itemL }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(itemL, (itemD) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: itemD,
                          class: _normalizeClass(!itemD.hasIcon ? 'not-allowed' : 'pointer')
                        }, [
                          (itemD.hasIcon)
                            ? (_openBlock(), _createElementBlock("i", _hoisted_15))
                            : _createCommentVNode("", true),
                          _createElementVNode("img", {
                            src: itemD.image,
                            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.navigate && _ctx.navigate(...args)))
                          }, null, 8, _hoisted_16),
                          _createElementVNode("label", null, _toDisplayString(_ctx.$filters.hh_t(itemD.label)), 1)
                        ], 2))
                      }), 128))
                    ]))
                  }), 128))
                ], 2))
              }), 128))
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("h6", _hoisted_18, _toDisplayString(_ctx.$filters.hh_t('hybrid')), 1),
            _createElementVNode("ul", _hoisted_19, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trainingCertificationConfig.levelBadgeO, (item) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: "not-allowed",
                  key: item
                }, [
                  _createElementVNode("img", {
                    src: item.image
                  }, null, 8, _hoisted_20)
                ]))
              }), 128))
            ])
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("h6", _hoisted_22, _toDisplayString(_ctx.$filters.hh_t('administrativeManagement')), 1),
            _createElementVNode("ul", _hoisted_23, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trainingCertificationConfig.levelBadgeO, (item) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: "not-allowed",
                  key: item
                }, [
                  _createElementVNode("img", {
                    src: item.image
                  }, null, 8, _hoisted_24)
                ]))
              }), 128))
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_25, [
      _createElementVNode("div", _hoisted_26, [
        _createElementVNode("h4", _hoisted_27, _toDisplayString(_ctx.$filters.hh_t('obtain')), 1),
        _createElementVNode("div", _hoisted_28, [
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("label", _hoisted_30, _toDisplayString(_ctx.$filters.hh_t('topResources')), 1),
            _createElementVNode("h5", _hoisted_31, _toDisplayString(_ctx.$filters.hh_t('learn')), 1),
            _createElementVNode("p", _hoisted_32, _toDisplayString(_ctx.$filters.hh_t('relatedCourses')), 1)
          ]),
          _createElementVNode("div", _hoisted_33, [
            _createElementVNode("img", {
              src: 
                _ctx.isEs
                  ? require('@/assets/img/main/training/ESCBG.jpeg')
                  : require('@/assets/img/main/training/RUCBG.jpeg')
              
            }, null, 8, _hoisted_34)
          ])
        ])
      ])
    ])
  ]))
}